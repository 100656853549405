@import "../../scss/base";

.Home-conditions {
  padding: 20px;
}

.Home-mainText {
  margin-top: 60px;
  margin-bottom: 60px;

  @include md {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  @include lg {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}
