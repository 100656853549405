body {
  overflow-x: hidden;
  margin: 0;
  position: relative;
  padding: 0;
  height: 100%;
  background-color: #ddd;
}

a,
select,
input,
button {
  padding: 0;
  outline: none;
  border: none;
  background: none;
}
