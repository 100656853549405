html {
  -webkit-font-smoothing: antialiased;
  font-family: 'Rabelo', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 10px;
}

// Mixins
@mixin large-text {
  font-size: 3.5rem;
  line-height: 4rem;

  @include lg {
    font-size: 10rem;
    line-height: 12rem;
  }
}

@mixin body-copy {
  font-size: 1.8rem;
  line-height: 2.5rem;

  @include lg {
    font-size: 2.3rem;
    line-height: 4rem;
  }
}

body {
  @include body-copy;
}
