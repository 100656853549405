@import "../../scss/base";

.ErrorHandler {
  min-height: calc(100vh - 40px);
  background: linear-gradient(to right, $white 0%,$blue 62%,$blue 100%);
  padding-top: 40px;

  @include md {
    min-height: calc(100vh - 60px);
    padding-top: 60px;
  }

  @include lg {
    min-height: calc(100vh - 80px);
    padding-top: 80px;
  }
}

.ErrorHandlerTitle {
  line-height: 40px;
}

.ErrorHandlerText {
  @include body;
}

.ErrorHandlerReload {
  @include body;
  color: $blue;
  cursor: pointer;
  transition: color $time;
  margin: 0 5px;
  font-weight: bold;

  @include on-enter {
    color: $hover;
  }
}
