@import "../../../scss/base";

.Footer-logoContainer {
  width: 180px;
  margin-bottom: 20px;

  @include md {
    width: 100px;
  }

  @include lg {
    width: 200px;
  }
}

.Footer-items {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include md {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.Footer-item {
  display: flex;
  color: $white;
  align-items: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px 5px;

  @include md {
    margin: 0 5px;
  }
}

.Footer-wrapper {
  background: $light-gray;
  @include footer-text;
  padding: 30px 0;

  @include md {
    padding: 40px 0;
  }

  @include md {
    padding: 50px 0;
  }
}


.Footer-title {
  @include footer-title;
}

.Footer-iconsContainer {
  display: flex;
  margin-top: 5px;

  .Footer-icon:first-child {
    margin-right: 15px;
  }
}

.Footer-icon {
  @include on-enter {

      opacity: 0.5;

  }
}
