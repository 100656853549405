@import "../../../scss/base";

.Header-wrapper {
  background: $light-gray;
  padding-top: 10px;
  padding-bottom: 10px;

  @include md {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @include lg {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.Header {
  position: relative;
  height: 70px;
  display: flex;
  justify-content: space-between;

  align-items: center;

  @include md {
    justify-content: space-between;
    height: 120px;
  }

  @include lg {
    height: 140px;
  }
}

.Header-logoContainer {
  width: 30vw;
  svg {
    width: 100%;
    height: auto;
  }

  @include sm-only {
    width: 40vw;
  }
}

.Header-flagContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;

  & .Header-flag {
    svg {
      height: 20px;
    }

    @include md {
      svg {
        height: 25px;
      }
    }

    @include lg {
      svg {
        height: 30px;
      }
    }
  }
}

.Header-iconsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;

  a:nth-child(2) {
    svg {
      height: 20px;

      @include md {
        height: 25px;
      }

      @include lg {
        height: 30px;
      }

      path {
        fill: $white;
      }
    }
  }

  a svg {
    height: 25px;
    margin-top: 2.5px;
    margin-left: 10px;
  }

  @include md {
    margin-bottom: 0;

    a svg {
      height: 30px;
      margin-left: 20px;
    }
  }

  @include lg {
    a svg {
      height: 40px;
      margin-right: 15px;
    }
  }

  a:first-child {
    svg {
      height: 20px;
      margin-top: 2.5px;

      path {
        fill: $white;
      }
    }

    @include md {
      display: none;
    }
  }
}

.Header-logo {
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-blend-mode: luminosity;
  background-color: #424242;
}

.Header-contactContainer {
  @include flex-basis(40%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include md {
    position: absolute;
    top: 0;
    right: -30px;
    height: 100%;
    @include flex-basis(auto)
  }

  @include lg {
    right: -80px;
  }

  svg {
    height: 30px;
  }
}

.Header-contactWrapper {
  display: none;
  color: $white;

  @include md {
    display: flex;
    align-items: center;

    svg {
      height: 25px;
      margin-top: -2.5px;
      margin-right: 5px;

      path {
        fill: $white;
      }
    }
  }

  @include lg {
    svg {
      height: 30px;
    }
  }
}

.Header-mail {
  display: none;

  @include md {
    display: block;
  }
}

.Header-flag {
  margin-left: 20px;

  svg {
    width: 40px;
  }

  @include lg {
    margin-left: 40px;
  }

  div {
    width: 30px;
    height: 20px;

    background-size: cover;
    background-position: center center;

    @include md {
      width: 30px;
      height: 25px;
    }

    @include lg {
      width: 40px;
      height: 30px;
    }
  }
}

.Header-telephone {
  color: $white;
  text-decoration: none;
}