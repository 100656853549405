.Cars-image {
  height: 100%;
  background-size: cover;
  background-position: center center;

}

.Cars-imageWrapper {
  margin-bottom: 10px;
}
