@import "../../../scss/base";

.BookButton-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.BookButton-container {
  align-self: center;
}

.BookButton {
  @include body-copy;
  color: $white;
  opacity: 1;
  border: thin solid $black;
  border-radius: 5px;
  padding: 7px 15px;
  background: $orange;
  transition: color $time;
  cursor: pointer;

  @include on-enter {
    background: $hover;
  }

  &:disabled {
    opacity: 0.3;
    border-color: rgba($black, 0.3);
    cursor: default;
  }
}

.BookButton-label {
  @include sm-only {
    display: none;
  }
}

.BookButton-label--sm {
  @include md {
    display: none;
  }
}
