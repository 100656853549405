@import "../../scss/base";

.Conditions {
  padding-top: 40px;

  @include md {
    padding-top: 80px;
  }

  @include lg {
    padding-top: 100px;
  }
}
