$font-family-sans-serif: 'Rabelo', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// ------ Colors
$time: 250ms;

// ------ Colors
$black: #000;
$white: #fff;
$grey: #ebe8e1;
$blue: #000972;
$orange: #2b2826;
$light-gray: #424242;
$hover: #545454;


// ------ Breakpoints
$breakpoint-lg: 1100px;
$breakpoint-md: 600px;

// Grid
$grid-col-count-sm: 4;
$grid-col-count-md: 12;
$grid-col-count-lg: 12;

$col-gutter: 2rem;

// on change of $container-width-xx adjust $grid-page-padding-xx
$container-width-sm: 85.33%;
$container-width-md: 84.63%;
$container-width-lg: 73.61%;
$container-width-xl: 74.22%;

// Navigation
$grid-page-height-sm: 22rem;
$grid-page-height-md: 22rem;
$grid-page-height-lg: 36rem;
$grid-page-height-xl: 65rem;

// These need to match JavaScript variables in pages/Navigation/utils.ts
// and be equal to 100 - $container-width-xx
$grid-page-padding-sm: 7.335%;
$grid-page-padding-md: 7.685%;
$grid-page-padding-lg: 13.195%;
$grid-page-padding-xl: 12.89%;
