@import "../../../scss/base";

.Form {
  display: flex;
  flex-direction: column;
  margin: 80px 0;
}

.Form-title {
  display: inline-block;
  margin: 20px auto;
}

.Form-selectWrapper {
  display: flex;
  flex-direction: column;
  @include sm-only {
    margin-bottom: 40px;
  }


  label {
    @include body;
    color: $black;
  }

  select {
    color: rgba($black, 0.5);
    @include body;
  }

  select.Form-select--hasValue {
    color: $black;
  }
}

.Form-container {
  margin-bottom: 20px;

  @include md {
    margin-bottom: 40px;
  }

  @include lg {
    margin-bottom: 60px;
  }
}

.Form-inputContainer {
  margin-bottom: 10px;

  label {
    @include body;
  }

  .Col {
    display: flex;
    min-height: 28px;
  }
}

.Form-select {
  width: 100%;
}

.Form-input {
  border: thin solid $black;
  border-radius: 5px;
  width: 100%;
  min-height: 28px;
  padding-left: 10px;
  @include body;
}

.Form-input--hasError {
  border-color: red;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.Form-accessories {
  margin: 10px 0;
  font-weight: bold;
}

.Form-dates {
  .Col {
    margin-bottom: 10px;
    position: relative;

    svg {
      position: absolute;
      right: 20px;
      top: 2px;

      @include md {
        display: none;
      }

      @include lg {
        display: inline;
      }
    }
  }

  .react-datepicker-popper {
    transform-origin: 0px 20px;
    transform: translate3d(10px, 35px, 0px) scale(1.2) !important;
  }
}

.Form-conditions {
  @include body;
}

.Form-conditionsLink {
  color: $blue;
  text-decoration: none;
  cursor: pointer;
  transition: color $time;

  @include on-enter {
      color: #4854de;
  }
}

.Form-contact {
  .Col {
    margin-bottom: 10px;
  }

  textarea {
    resize: none;
    min-height: 200px;
    padding-top: 7px;
    background: transparent;
  }
}

.Form-contactWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Form-required {
  @include body;
  margin-top: 20px;
}

.Form-submit {
  padding: 10px;
  background: $grey;
  border: $black;
  border-radius: 5px;
}

.Form-submitButton {
  display: flex;

  svg {
    width: 40px;
    display: block;
  }

  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle {
      animation: dash .9s ease-in-out;
    }
    &.line {
      stroke-dashoffset: 1000;
      animation: dash .9s .35s ease-in-out forwards;
    }
    &.check {
      stroke-dashoffset: -100;
      animation: dash-check .9s .35s ease-in-out forwards;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

}

.Form-submitMessage {
  margin-left: 20px;
  display: flex;
  align-items: center;

  span {
    margin-left: 20px;
  }
}

.Form-bookButton {
  @include body-copy;
  color: $white;
  opacity: 1;
  border: thin solid $black;
  border-radius: 5px;
  padding: 7px 15px;
  background: $orange;
  transition: color $time;
  cursor: pointer;
  min-width: 90px;
  min-height: 46.5px;

  @include on-enter {
    &:enabled {
      background: $hover;
    }
  }

  &:disabled {
    opacity: 0.3;
    border-color: rgba($black, 0.3);
    cursor: default;
  }

  @include lg {
    min-height: 57px;
    min-width: 110px;
  }
}
