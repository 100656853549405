@import "../../../scss/base";

.NavBar-wrapper {
  display: flex;
  width: 100%;

  @include md {
    display: none;
  }
}

.NavBar-wrapper--sm {
  @include sm-only {
    display: none;
  }
}

.NavBar-container {
  border-top: thin solid $black;
  border-bottom: thin solid $black;
  background: $orange;
  display: flex;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000000;

  @include md {
    justify-content: center;
    height: 60px;
  }

  @include lg {
    height: 80px;
  }
}

.NavBar-hamburgerButton {
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  margin-left: auto
}

.NavBar {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: color $time;

  * {
    cursor: pointer;
  }

  a {
    color: $white;
    text-decoration: none;

    @include on-enter {
      color: $hover;
    }
  }
}



.NavBar-item {
  color: $white;
  transition: color $time;

  @include md {
    color: $white;
  }

  @include on-enter {
    color: $hover;
  }
}


/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: $light-gray;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow: hidden !important;
}

/* Wrapper for item list */
.bm-item-list {
  color: $grey !important;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: $white;
  text-decoration: none;
  margin-bottom: 10px;

  cursor: pointer;

  @include sm-only {
    @include on-enter {
      color: $hover;
    }
  }
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}
