@font-face {
  font-family: 'Rabelo';
    src: url('../assets/font/Rabelo.eot');
    src: url('../assets/font/Rabelo.eot?#iefix') format('embedded-opentype'),
        url('../assets/font/Rabelo.woff2') format('woff2'),
        url('../assets/font/Rabelo.woff') format('woff'),
        url('../assets/font/Rabelo.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


