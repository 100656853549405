@import "../../../scss/base";

.Discount-wrapper {
  margin: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $light-gray;
}

.Discount-button {
  display: flex;
  justify-content: flex-end;
}

.Discount-container {
  align-self: center;
  h2 {
    color: $white;
  }
}

.Discount {
  display: flex;
  align-items: center;
}
